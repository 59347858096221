.leaflet-container {
    // MARKER CLUSTER
    .marker-cluster {
        width: 54px;
        height: 54px;

        &-small,
        &-medium,
        &-large {
            background-color: $brand-04;

            & div {
                font-family: 'BreveDisplay', serif;
                color: $brand-03;
                font-size: 24px;
                background-color: $brand-04;
            }
        }
    }

    //POPUP
    .leaflet-popup {
        .leaflet-popup-content-wrapper {
            border-radius: 0;
            padding: 0;
            max-width: 200px;
            width: 100%;

            & .leaflet-popup-content {
                margin: 0;

                & p {
                    margin: 0;
                }
            }
        }
        & .leaflet-popup-close-button {
            display: none;
        }
    }

    // CONTROLS

    .leaflet-control-container {
        & .leaflet-top {
            top: auto;
            bottom: 50px;
            left: auto;
            right: 10px;
        }
        & .leaflet-control-zoom {
            border: 1px solid $ui-04;
            border-radius: 0;

            & .leaflet-control-zoom-in,
            & .leaflet-control-zoom-out {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                & span {
                    font-size: 24px;
                    line-height: 40px;
                    color: $brand-01-600;
                }
            }
        }
    }
}
