.rav-cmsContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin-bottom: 120px;

    & h1 {
        @include titleM;
        margin-bottom: 20px;
    }
    & h2 {
        @include titleS;
        margin-bottom: 20px;

        &:not(:first-of-type) {
            padding-top: 44px;
        }
    }
    & p {
        @include textM;
        width: 100%;
        margin-bottom: 12px;

        & a {
            @include textM(700, $brand-01-600);
        }
        & img {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}
@media screen and (max-width: 1023px) {
    .rav-cmsContent {
        margin-bottom: 80px;

        & h2 {
            margin-bottom: 12px;

            &:not(:first-of-type) {
                padding-top: 28px;
            }
        }
        & p {
            margin-bottom: 12px;
        }
    }
    body.-overflowHidden {
        overflow: hidden;
    }
}

.rav-page.-home,
.rav-page.-recipes,
.rav-page.-category {
    & .rav-cmsContent {
        margin-bottom: 0;
    }
}
