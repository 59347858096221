@media screen and (max-width: 1024px) {
    .rav-carousel {
        padding-bottom: 24px;
        position: relative;

        & [class*='swiper-pagination']:not(.swiper-pagination-bullet) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 8px;
            padding-top: 16px;
            box-sizing: content-box;
            position: absolute;
            bottom: 0;

            & .swiper-pagination-bullet {
                background-color: rgba(#8e2d7f, 0.25);
                opacity: 1;
                transform: scale(1);
                transform-origin: center;
                transition: transform 0.35s ease, background-color 0.35s linear;

                &-active {
                    background-color: #afb72e;
                    transform: scale(0.625);
                }
            }
        }
    }
}
.rav-overlayCard {
    min-width: 320px;
}
